import React, { Suspense, useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import { 
  OrbitControls, 
  useGLTF, 
  useAnimations,
  Environment,
} from '@react-three/drei'

import tetracono from './tetracono.glb'
import sky from './sky.hdr'


const cameraParams = {
  position: [-0.5, 0.25, 1],
  fov: 25,
}


const Tetracono = () => {
  const { scene, animations } = useGLTF(tetracono)
  const { actions } = useAnimations(animations, scene)
  
  useEffect(() => {
    Object.entries(actions).forEach(([k, action]) => action.play())
  }, [actions])

  scene.traverse((o) => {
    if ( o.material ) {
      switch ( o.material.name ) {
        case 'WHITE':
        case 'RED_ENAMEL':
        case 'GREEN_ENAMEL':
          o.receiveShadow = true
          break
        case 'BLACK_PLYWOOD':
          o.castShadow = true
          break
      }
    }
  })

  return <primitive object={scene} />
}

const Scene = () => {
  return (
    <Canvas camera={cameraParams} shadows >
      <OrbitControls />
      <directionalLight
        position={[0, 5, 2]}
        intensity={2.0}
        castShadow
      />
      <ambientLight intensity={0.5} />
      <Suspense fallback={null}>
        <Tetracono />
        <Environment files={sky} />
      </Suspense>
    </Canvas>
  )
}

export default Scene
